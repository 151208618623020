import React from 'react'
import AccordeonComponent2 from './AccordeonComponent2';
import Accordeon from './Accordeon';

const FAQ = () => {
  return (
    <section className='bg-white px-3 py-5 sm:mx-5 text-center text-lg text-indigo-800 font-gabriela text-justify'>
       
    <AccordeonComponent2/>


        
    </section>


  )
}

export default FAQ