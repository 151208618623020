// import React, { useRef, useState } from "react";
// import cloud from '../images/cloud.webp'
// import emailjs from '@emailjs/browser';

// const ContactForm = () => {
//   const form = useRef();

//   const x1 = process.env.REACT_APP_BANANE;
//   const x2 = process.env.REACT_APP_ORANGE;
//   const x3 = process.env.REACT_APP_KIWI;

//   const [successMessage, setSuccessMessage] = useState(null);
//   const [errorMessage, setErrorMessage] = useState(null);

//   const sendEmail = (e) => {
//     e.preventDefault();
//     const name = form.current.user_name.value;
//     const email = form.current.user_email.value;
//     const message = form.current.message.value;
//     const reason = form.current.reason.value;

//     if (!name || !email || !message || !reason) {
//       setErrorMessage('Veuillez remplir tous les champs');
//       setSuccessMessage(null);
//       return;
//     }

//     // Validation de l'adresse e-mail
//     const emailPattern = /^[a-zA-Z0-9._%+-]{2,}@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/ ;
    
//     if (!emailPattern.test(email)) {
//       setErrorMessage('Veuillez saisir une adresse e-mail valide');
//       setSuccessMessage(null);
//       return;
//     }

//     emailjs.sendForm(x1, x2, form.current, x3)
//       .then((result) => {
//         setSuccessMessage('Votre message a bien été envoyé');
//         setErrorMessage(null);
//       })
//       .catch((error) => {
//         setErrorMessage('Une erreur est survenue lors de l\'envoi du message');
//         setSuccessMessage(null);
//       });
//   };

//   return (
//     <div className="formulaireDeContact">
//       <div className="h-[70px]"></div>
//       <section id="contact" className='py-5 bg-cover' style={{ backgroundImage: `url(${cloud})` }}>
//         <h2 className='text-center text-3xl text-white font-bold font-gabriela' id='contactme'>Contactez-moi</h2>
//         <div className='w-[300px] mx-auto sm:w-[600px] lg:w-[800px]'>

//           <form className="mx-auto mt-10" ref={form} onSubmit={sendEmail}>
//             {successMessage && (
//               <div className="text-green-500 text-center text-xl">{successMessage}</div>
//             )}
//             {errorMessage && (
//               <div className="text-red-500 text-center text-xl">{errorMessage}</div>
//             )}
//             <div className="mb-5">
//               <label htmlFor="reason" className="block text-gray-700 text-md font-bold mb-2 font-gabriela">
//                 Objet du message:
//               </label>
//               <select id="reason" name="reason" className="block appearance-none w-full border border-gray-400 py-2 px-3 rounded-lg leading-tight focus:outline-none focus:border-gray-500">
//                 <option value="">--Choisissez une option--</option>
//                 <option value="demande de prestation">Demande de prestation</option>
//                 <option value="question à vous poser">Question à vous poser</option>
//                 <option value="autre">Autre</option>
//               </select>
//             </div>
//             <div className="mb-5">
//               <label htmlFor="name" className="block text-gray-700 text-md font-bold mb-2 font-gabriela">
//                 Nom et prénom:
//               </label>
//               <input type="text" id="name" name="user_name" className="font-gabriela shadow appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Entrez votre nom et prénom" />
//             </div>
//             <div className="mb-5">
//               <label htmlFor="email" className="block text-gray-700 text-md font-bold mb-2 font-gabriela">
//                 Adresse e-mail:
//               </label>
//               <input type="email" id="user_email" name="user_email" className="shadow appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Entrez votre adresse e-mail" />
//             </div>
//             <div className="mb-5">
//               <label htmlFor="message" className="block text-gray-700 text-md font-bold mb-2 font-gabriela">
//                 Message:
//               </label>
//               <textarea id="message" name="message" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" rows="5" placeholder="Entrez votre message ici"></textarea>
//             </div>
//             <div className="flex justify-center">
//               <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full w-[200px] focus:outline-none focus:shadow-outline">
//                 Envoyer
//               </button>
//             </div>
//           </form>
//         </div>
//       </section>
//     </div>
//   );
// }

// export default ContactForm;
import React, { useRef, useState } from "react";
import cloud from '../images/cloud.webp';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
  const form = useRef();

  const x1 = process.env.REACT_APP_BANANE;
  const x2 = process.env.REACT_APP_ORANGE;
  const x3 = process.env.REACT_APP_KIWI;

  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const sendEmail = (e) => {
    e.preventDefault();
    const name = form.current.user_name.value;
    const email = form.current.user_email.value;
    const message = form.current.message.value;
    const reason = form.current.reason.value;

    if (!name || !email || !message || !reason) {
      setErrorMessage('Veuillez remplir tous les champs');
      setSuccessMessage(null);
      setTimeout(() => setErrorMessage(null), 2000);
      return;
    }

    // Validation de l'adresse e-mail
    const emailPattern = /^[a-zA-Z0-9._%+-]{2,}@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/ ;
    
    if (!emailPattern.test(email)) {
      setErrorMessage('Veuillez saisir une adresse e-mail valide');
      setSuccessMessage(null);
      setTimeout(() => setErrorMessage(null), 2000);
      return;
    }

    emailjs.sendForm(x1, x2, form.current, x3)
      .then((result) => {
        setSuccessMessage('Merci pour votre message !');
        setErrorMessage(null);
        form.current.reset();
        setTimeout(() => setSuccessMessage(null), 2000);
      })
      .catch((error) => {
        setErrorMessage('Une erreur est survenue lors de l\'envoi du message');
        setSuccessMessage(null);
        setTimeout(() => setErrorMessage(null), 2000);
      });
  };

  return (
    <div className="formulaireDeContact">
      <div className="h-[70px]"></div>
      <section id="contact" className='py-5 bg-cover' style={{ backgroundImage: `url(${cloud})` }}>
        <h2 className='text-center text-3xl text-white font-bold font-gabriela' id='contactme'>Contactez-moi</h2>
        <div className='w-[300px] mx-auto sm:w-[600px] lg:w-[800px]'>

          <form className="mx-auto mt-10" ref={form} onSubmit={sendEmail}>
            {successMessage && (
              <div className="text-white bg-green-400 text-center text-lg px-5">{successMessage}</div>
            )}
            {errorMessage && (
              <div className="text-white bg-red-400 text-center text-lg px-5">{errorMessage}</div>
            )}
            <div className="mb-5">
              <label htmlFor="reason" className="block text-gray-700 text-md font-bold mb-2 font-gabriela">
                Objet du message:
              </label>
              <select id="reason" name="reason" className="block appearance-none w-full border border-gray-400 py-2 px-3 rounded-lg leading-tight focus:outline-none focus:border-gray-500">
                <option value="">--Choisissez une option--</option>
                <option value="demande de prestation">Demande de prestation</option>
                <option value="question à vous poser">Question à vous poser</option>
                <option value="autre">Autre</option>
              </select>
            </div>
            <div className="mb-5">
              <label htmlFor="name" className="block text-gray-700 text-md font-bold mb-2 font-gabriela">
                Nom et prénom:
              </label>
              <input type="text" id="name" name="user_name" className="font-gabriela shadow appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Entrez votre nom et prénom" />
            </div>
            <div className="mb-5">
              <label htmlFor="email" className="block text-gray-700 text-md font-bold mb-2 font-gabriela">
                Adresse e-mail:
              </label>
              <input type="email" id="user_email" name="user_email" className="shadow appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Entrez votre adresse e-mail" />
            </div>
            <div className="mb-5">
              <label htmlFor="message" className="block text-gray-700 text-md font-bold mb-2 font-gabriela">
                Message:
              </label>
              <textarea id="message" name="message" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" rows="5" placeholder="Entrez votre message ici"></textarea>
            </div>
            <div className="flex justify-center">
              <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full w-[200px] focus:outline-none focus:shadow-outline">
                Envoyer
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}

export default ContactForm;
