import React from 'react'
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';

const ww = process.env.REACT_APP_COCO

const containerStyle = {
  width: '100%',
  height: '100%'
};

const center = {
  lat: 43.700008392333984,
  lng: 7.27925968170166
};



const renderMarkers = (map, maps) => {
  let marker = new maps.Marker({
  position: { lat: center.lat, lng: center.lng },
  map,
  title: 'Hello World!'
  });
  return marker;
 };
 const labelCaract = {
  color: 'red', 
  fontWeight: 'bold', 
  fontSize: '22px', 
  text: 'Patrick Collet',
  className:'labelmap'

 }

function SimpleMap() {
  return (
    <div className='mb-7 w-[600px] sm:w-[900px] lg:w-[1200px] xl:w-[1400px] 2xl:w-[1600px] h-[500px] px-3 z-[500]'>
      <LoadScript
        googleMapsApiKey={ww}
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={15}
          onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
          
        >
          <MarkerF position={center} visible={true} label={labelCaract}  zIndex='500'/>
          
        </GoogleMap>
      </LoadScript>
    </div>
  )
}

export default React.memo(SimpleMap)
