import './App.css'
import {Routes, Route} from 'react-router-dom'
import Prestations2 from './components/Prestations2';
import Contact from './components/Contact';
import Erreur from './components/Erreur';

import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import PresentationAuteur from './components/PresentationAuteur';
import AffichageNouvelle from './components/AffichageNouvelle';
import Menu4FinalWrap from './components/Menu4FinalWrap';
import Blog from './components/Blog';
import CookieConsentBanner from './components/cookieConsentBanner';


function App() {
  return (
    <div>
        <Menu4FinalWrap/>


    <ScrollToTop/>
    <Routes>
      <Route path='/' element={<PresentationAuteur/>} />
      <Route path='/presentationauteur' element={<PresentationAuteur/>} />
      <Route path='/prestations' element={<Prestations2/>} />
      <Route path='/contact' element={<Contact/>} />
      <Route path='/blog' element={<Blog/>} />
      <Route path='/nouvelle/:id' element={<AffichageNouvelle />} />
      <Route path='/*' element={<Erreur/>} />
    </Routes>
    <Footer/>
    <CookieConsentBanner/>

    </div>

              
  );
}

export default App;
