import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { FaPenNib } from 'react-icons/fa';
const Menu4 = () => {
  let Links = [
    {name:"Qui suis-je ?", link:"/"},
    {name:"blog", link:"/blog"},
    {name:"Prestations", link:"/"},
    {name:"Contact", link:"/"}
  ]
  const handleclk = () => {
    setOpen(!open)
  }
  const handleToggle = () => {
    setOpen(!open);
  };
  let [open, setOpen] = useState(false)

  return (
    // <div className={`h-[50px] fixed w-full z-[999] ${open ? "" : "closed"}`} id="gen">
    <div className={`h-[50px] fixed w-full z-[999]`} id="gen">
      <div className='w-full top-0 left-0'>
        <div className='lg:flex items-center justify-between bg-white py-4 lg:px-10 px-7'>
          <div className='font-bold text-2xl cursor-pointer flex items-center text-gray-800'>
            <span className='mr-1 text-3xl flex'>
              {/* <ion-icon name="book-outline"></ion-icon> */}
              <div className='containerLogoPC'>
              <Link to="/"><FaPenNib className='flexFaPen'/><span className='auteur'>Patrick Collet</span></Link>

              </div>
            </span>
          
          </div>
          {/* <div onClick={()=>handleclk()} className='text-3xl absolute right-8 top-5 sursor-pointer lg:hidden '> */}
          <div className='text-3xl absolute right-10 top-9 sursor-pointer lg:hidden '>
          <div className="trigger" onClick={handleToggle}>
              <svg className={`bars ${open ? 'active' : ''}`} viewBox="0 0 100 100">
                <path className="line top" d="m 30,33 h 40 c 13.100415,0 14.380204,31.80258 6.899646,33.421777 -24.612039,5.327373 9.016154,-52.337577 -12.75751,-30.563913 l -28.284272,28.284272"></path>
                <path className="line middle" d="m 70,50 c 0,0 -32.213436,0 -40,0 -7.786564,0 -6.428571,-4.640244 -6.428571,-8.571429 0,-5.895471 6.073743,-11.783399 12.286435,-5.570707 6.212692,6.212692 28.284272,28.284272 28.284272,28.284272"></path>
                <path className="line bottom" d="m 69.575405,67.073826 h -40 c -13.100415,0 -14.380204,-31.80258 -6.899646,-33.421777 24.612039,-5.327373 -9.016154,52.337577 12.75751,30.563913 l 28.284272,-28.284272"></path>
              </svg>
            </div>
            {/* <ion-icon name={open ? "close": "menu"}></ion-icon> */}
            
          </div>
          {/* <ul className={`lg:static lg:flex lg:items-center lg:pb-0 pb-12 lg:pl-0 pl-9 transition-all duration-500 ease-in`} style={{ transform: open ? "translateY(20px)" : "translateY(-490px)" }}> */}

          <ul className={`lg:static lg:flex lg:items-center lg:pb-0 pb-12 lg:pl-0 pl-9 transition-all duration-500 ease-in ${!open ?"hidden":"top-[-490px]"}`}>
            <li className='lg:ml-8 text-xl lg:my-0 my-7 no-underline'>
              <Link to="/" className='rubriqueMenu no-underline text-gray-800 hover:text-gray-400 duration-500'
              onClick={()=>handleclk()}>Qui suis-je ?</Link>
            </li>
            <li className='lg:ml-8 text-xl lg:my-0 my-7 no-underline'>
              <Link to="/blog" className='no-underline text-gray-800 hover:text-gray-400 duration-500'
              onClick={()=>handleclk()}>Blog</Link>
            </li>
            <li className='lg:ml-8 text-xl lg:my-0 my-7 no-underline'>
              <Link to="/prestations" className='no-underline text-gray-800 hover:text-gray-400 duration-500'
              onClick={()=>handleclk()}>Prestations</Link>
            </li>
            <li className='lg:ml-8 text-xl lg:my-0 my-7 no-underline'>
              <Link to="/contact" className='no-underline text-gray-800 hover:text-gray-400 duration-500'
              onClick={()=>handleclk()}>Contact</Link>
            </li>
          </ul>
        </div>
      </div>

    </div>
  )
}

export default Menu4