import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import axios from 'axios';

function Paginated({ itemsPerPage, currentPage, onPageChange, children }) {
  const [articles, setArticles] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get('https://luckycheetah.fun/apibiographe/fetchnouvelles');
        const sortedArticles = response.data.documents.sort((a, b) => {
          // Convertir les dates en objets Date pour la comparaison
          const dateA = new Date(a.date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));
          const dateB = new Date(b.date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));
          // Trier les articles par date décroissante
          return dateB - dateA;
        });
        setArticles(sortedArticles || []);
      } catch (error) {
        console.error('Erreur lors de la récupération des articles:', error);
      }
    };

    fetchArticles();
  }, []);

  useEffect(() => {
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const newCurrentItems = articles.slice(startIndex, endIndex);
    setCurrentItems(newCurrentItems);
  }, [articles, currentPage, itemsPerPage]);

  const handlePageClick = ({ selected }) => {
    onPageChange(selected);
  };

  const pageCount = Math.ceil(articles.length / itemsPerPage);

  return (
    <div>
      {React.Children.map(children, child =>
        React.cloneElement(child, { currentItems })
      )}
      <ReactPaginate
        breakLabel="..."
        nextLabel="suivant >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel="< précédent"
        containerClassName='pagination pagination-inline'
        pageClassName="page-item"
        activeClassName="activepage"
        pageLinkClassName="page-link"
        forcePage={currentPage} 
      />
    </div>
  );
}

export default Paginated;
