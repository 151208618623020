import React from 'react'
import FAQ from './FAQ'
import {HiPencilAlt} from 'react-icons/hi'
const DemarcheMethode = () => {
  return (
    <div className=''>
        <div className='text-justify text-2xl font-cormorantgaramond'>


            <div className='text-justify font-cormorantgaramond'>
                <div className=''>
                    <h2 className='text-2xl text-indigo-800 font-bold text-center font-gabriela py-5'>Je m'engage auprès de vous</h2>      
                    <p className='text-xl text-zinc-500'>
                        <ul className='font-playfair list-disc sm:list-inside px-10 list-none'>
                            <li className=''><HiPencilAlt className='inline pr-2 text-4xl text-indigo-800'/>Je suis un professionnel souple et à l'écoute, <span className='font-bold text-indigo-800'>toujours prêt à m'adapter à vos besoins</span> pour vous offrir un service efficace et personnalisé. 
                            Mes séances d'une heure peuvent se dérouler en présentiel ou en visioconférence, selon vos préférences.
                            </li>
                            <br></br>
                            
                            <li className=''>
                            <HiPencilAlt className='inline pr-2 text-4xl text-indigo-800'/>Lors de chaque séance, j'enregistre notre échange pour être sûr de pouvoir <span className='font-bold text-indigo-800'>restituer votre histoire </span>avec exactitude. 
                            Ma méthode est empirique, s'adaptant aux différentes personnalités. 
                            Mon objectif est de faire émerger les idées qui vous animent.
                            </li>
                            <br></br>

                            <li className=''>
                            <HiPencilAlt className='inline pr-2 text-4xl text-indigo-800'/> 
                            En tant que biographe, je m'efforce de travailler à votre rythme, selon vos besoins et vos attentes.
                            </li>
                            <br></br>

                            <li className=''>
                            <HiPencilAlt className='inline pr-2 text-4xl text-indigo-800'/>Je m'efforce de <span className='font-bold text-indigo-800'>vous donner envie </span>de découvrir avec impatience les textes que je vous transmettrai dès que je les aurai rédigés, pour que vous puissiez vous replonger dans vos souvenirs avec émotion et satisfaction.
                            </li>

                        </ul>
                    </p>
                </div>
            </div>
        </div>

        <div className='bg-white py-2'>
            <FAQ></FAQ>
        </div>

    </div>

  )
}

export default DemarcheMethode