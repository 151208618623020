
import carousel1 from '../images/ecrire-generation.webp'
import carousel2 from '../images/ecrire-liberation.webp'
import carousel3 from '../images/ecrire-passage.webp'
import carousel4 from '../images/ecrire-imaginer.webp'
import carousel5 from '../images/ecrire-precieux.webp'
import carousel6 from '../images/ecrire-injustices.webp'
import React, { useState, useEffect } from 'react';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { RxDotFilled } from 'react-icons/rx';

const images = {
  carousel1,
  carousel2,
  carousel3,
  carousel4,
  carousel5,
  carousel6
}

const SliderComponent = () => {

      const slides = [
        {
          url: images.carousel5,
          backgroundPositionY:'80%',
          backgroundPositionX:'70%',
          texte:'Écrire pour se rappeler les moments précieux et les relire à l\'infini',
          sm_VERTICAL:'top-[35px]',
          sm_HORIZONTAL:'left-[20px]',
          md_VERTICAL:'md:top-[30px]',
          md_HORIZONTAL:'md:left-[10px]',
          lg_VERTICAL:'lg:top-[190px]',
          lg_HORIZONTAL:'lg:left-[300px]',
          colorText:'text-blue-900',
        },
        {
          url: images.carousel4,
          backgroundPositionY:'25%',
          backgroundPositionX:'62%',
          texte:'Écrire pour créer un univers où tout est possible',
          sm_VERTICAL:'top-[70px]',
          sm_HORIZONTAL:'left-[20px]',
          md_VERTICAL:'md:top-[90px]',
          md_HORIZONTAL:'md:left-[30px]',
          lg_VERTICAL:'lg:top-[90px]',
          lg_HORIZONTAL:'lg:left-[120px]',
          colorText:'text-zinc-900',
        },
        {
          url: images.carousel3,
          backgroundPositionY:'90%',
          backgroundPositionX:'0%',
          texte:'Écrire pour laisser une trace de son passage',
          sm_VERTICAL:'top-[100px]',
          sm_HORIZONTAL:'left-[30px]',
          md_VERTICAL:'md:top-[100px]',
          md_HORIZONTAL:'md:left-[200px]',
          lg_VERTICAL:'lg:top-[160px]',
          lg_HORIZONTAL:'lg:left-[330px]',
          colorText:'text-zinc-600'
        },
        {
          url: images.carousel1,
          backgroundPositionY:'0%',
          backgroundPositionX:'30%',
          texte:'Écrire pour bâtir des ponts entre les générations',
          sm_VERTICAL:'top-[230px]',
          sm_HORIZONTAL:'left-[50px]',
          md_VERTICAL:'md:top-[250px]',
          md_HORIZONTAL:'md:left-[100px]',
          lg_VERTICAL:'lg:top-[150px]',
          lg_HORIZONTAL:'lg:left-[650px]',
          colorText:'text-purple-600'
        },
        {
          url: images.carousel2,
          backgroundPositionY:'60%',
          backgroundPositionX:'63%',
          texte:'Écrire pour se libérer du poids du passé',
          sm_VERTICAL:'top-[90px]',
          sm_HORIZONTAL:'left-[20px]',
          md_VERTICAL:'md:top-[90px]',
          md_HORIZONTAL:'md:left-[60px]',
          lg_VERTICAL:'lg:top-[90px]',
          lg_HORIZONTAL:'lg:left-[230px]',
          colorText:'text-zinc-600'
        },
        {
          url: images.carousel6,
          backgroundPositionY:'50%',
          backgroundPositionX:'20%',
          texte:'Écrire pour dénoncer les injustices et les oppressions',
          sm_VERTICAL:'top-[90px]',
          sm_HORIZONTAL:'left-[20px]',
          md_VERTICAL:'md:top-[90px]',
          md_HORIZONTAL:'md:left-[20px]',
          lg_VERTICAL:'lg:top-[150px]',
          lg_HORIZONTAL:'lg:left-[150px]',
          colorText:'text-red-900'
        },
        
      ];
    
      const [currentIndex, setCurrentIndex] = useState(0);
      const [timer, setTimer] = useState(null);

      const prevSlide = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
      };
    
      const nextSlide = () => {
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
      };
    
      const goToSlide = (slideIndex) => {
        setCurrentIndex(slideIndex);
      };

      const startAutoScroll = () => {
        setTimer(setInterval(() => {
            nextSlide();
        }, 5000)); // Défilement toutes les 3 secondes
      };
      const stopAutoScroll = () => {
          clearInterval(timer);
          setTimer(null);
      };
      // Démarrage du défilement automatique lors du montage du composant
    useEffect(() => {
      // Nettoyage du timer lors de la destruction du composant
      const imageList = Object.values(images);
      imageList.forEach((image) => {
        const img = new Image();
        img.src = image;
      });
      
      startAutoScroll();
      return () => {
          stopAutoScroll();
      };
  }, []);

  // Mise à jour du timer à chaque changement de currentIndex
  useEffect(() => {
      if (timer !== null) {
          stopAutoScroll();
          startAutoScroll();
      }
  }, [currentIndex]);

    
return (
<div className='relative h-[400px] lg:h-[600px] w-full m-auto relative group'>
      <div
        style={{ backgroundImage: `url(${slides[currentIndex].url})`, 
        backgroundPositionX: `${slides[currentIndex].backgroundPositionX}`, backgroundPositionY: `${slides[currentIndex].backgroundPositionY}` }}
        className='w-full h-full bg-cover duration-500'
      ></div>
      {/* Left Arrow */}
      <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
        <BsChevronCompactLeft onClick={prevSlide} size={30} />
      </div>
      {/* Right Arrow */}
      <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
        <BsChevronCompactRight onClick={nextSlide} size={30} />
      </div>


      <div className={`absolute 
      ${slides[currentIndex].sm_VERTICAL} ${slides[currentIndex].sm_HORIZONTAL}
      ${slides[currentIndex].md_VERTICAL} ${slides[currentIndex].md_HORIZONTAL}
      ${slides[currentIndex].lg_VERTICAL} ${slides[currentIndex].lg_HORIZONTAL}
      ${slides[currentIndex].colorText}
      font-gabriela text-center text-3xl sm:text-4xl lg:text-6xl
      `}>
        {slides[currentIndex].texte}
      </div>



      <div className='flex top-4 justify-center py-2'>
        {slides.map((slide, slideIndex) => (
          <div
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
            className='text-2xl cursor-pointer'
          >
            <RxDotFilled id='btnup'/>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SliderComponent