// import React, { useEffect } from 'react';
// import '../../src/accordeon.css';

// const Accordeon = ({ title, content }) => {

//   useEffect(() => {
//     const accordionToggles = document.querySelectorAll('.js-accordionTrigger');

//     const skipClickDelay = (e) => {
//       e.preventDefault();
//       e.target.click();
//     };

//     const setAriaAttr = (el, ariaType, newProperty) => {
//       el.setAttribute(ariaType, newProperty);
//     };

//     const setAccordionAria = (el1, el2, expanded) => {
//       switch (expanded) {
//         case "true":
//           setAriaAttr(el1, 'aria-expanded', 'true');
//           setAriaAttr(el2, 'aria-hidden', 'false');
//           break;
//         case "false":
//           setAriaAttr(el1, 'aria-expanded', 'false');
//           setAriaAttr(el2, 'aria-hidden', 'true');
//           break;
//         default:
//           break;
//       }
//     };

//     const switchAccordion = (e) => {
//       e.preventDefault();
//       const target = e.target;
//       const parentNode = target.parentNode;
      
//       if (parentNode) {
//         const thisAnswer = parentNode.nextSibling;
//         const thisQuestion = target;
        
//         if (thisAnswer && thisAnswer.classList.contains('is-collapsed')) {
//           setAccordionAria(thisQuestion, thisAnswer, 'true');
//         } else if (thisAnswer) {
//           setAccordionAria(thisQuestion, thisAnswer, 'false');
//         }
        
//         thisQuestion.classList.toggle('is-collapsed');
//         thisQuestion.classList.toggle('is-expanded');
        
//         if (thisAnswer) {
//           thisAnswer.classList.toggle('is-collapsed');
//           thisAnswer.classList.toggle('is-expanded');
//           thisAnswer.classList.toggle('animateIn');
//         }
//       }
//     };

//     const touchSupported = ('ontouchstart' in window);
//     const pointerSupported = ('pointerdown' in window);

//     accordionToggles.forEach(toggle => {
//       if (touchSupported) {
//         toggle.addEventListener('touchstart', skipClickDelay, false);
//       }
//       if (pointerSupported) {
//         toggle.addEventListener('pointerdown', skipClickDelay, false);
//       }
//       toggle.addEventListener('click', switchAccordion, false);
//     });

//     return () => {
//       accordionToggles.forEach(toggle => {
//         if (touchSupported) {
//           toggle.removeEventListener('touchstart', skipClickDelay, false);
//         }
//         if (pointerSupported) {
//           toggle.removeEventListener('pointerdown', skipClickDelay, false);
//         }
//         toggle.removeEventListener('click', switchAccordion, false);
//       });
//     };
//   }, []);

//   return (
//     <div className="container">
//       <div className="accordion">
//         <dl>
//           <dt>
//             <a href="#accordion1" aria-expanded="false" aria-controls="accordion1" className="accordion-title accordionTitle js-accordionTrigger">
//              {title}
//             </a>
//           </dt>
//           <dd className="accordion-content accordionItem is-collapsed" id="accordion1" aria-hidden="true">
//             <div className="containerProba">
//             {content}
//             zefzefzefzef
//             </div>
//           </dd>
//           <dt>
//           </dt>
//         </dl>
//       </div>
//     </div>
//   );
// };

// export default Accordeon;
import React, { useEffect } from 'react';
import '../../src/accordeon.css';

const Accordeon = ({ title, content, id }) => {

  useEffect(() => {
    const accordionToggles = document.querySelectorAll(`.js-accordionTrigger-${id}`);

    const skipClickDelay = (e) => {
      e.preventDefault();
      e.target.click();
    };

    const setAriaAttr = (el, ariaType, newProperty) => {
      el.setAttribute(ariaType, newProperty);
    };

    const setAccordionAria = (el1, el2, expanded) => {
      switch (expanded) {
        case "true":
          setAriaAttr(el1, 'aria-expanded', 'true');
          setAriaAttr(el2, 'aria-hidden', 'false');
          break;
        case "false":
          setAriaAttr(el1, 'aria-expanded', 'false');
          setAriaAttr(el2, 'aria-hidden', 'true');
          break;
        default:
          break;
      }
    };

    const switchAccordion = (e) => {
      e.preventDefault();
      const target = e.target;
      const parentNode = target.parentNode;
      
      if (parentNode) {
        const thisAnswer = parentNode.nextSibling;
        const thisQuestion = target;
        
        if (thisAnswer && thisAnswer.classList.contains('is-collapsed')) {
          setAccordionAria(thisQuestion, thisAnswer, 'true');
        } else if (thisAnswer) {
          setAccordionAria(thisQuestion, thisAnswer, 'false');
        }
        
        thisQuestion.classList.toggle('is-collapsed');
        thisQuestion.classList.toggle('is-expanded');
        
        if (thisAnswer) {
          thisAnswer.classList.toggle('is-collapsed');
          thisAnswer.classList.toggle('is-expanded');
          thisAnswer.classList.toggle('animateIn');
        }
      }
    };

    const touchSupported = ('ontouchstart' in window);
    const pointerSupported = ('pointerdown' in window);

    accordionToggles.forEach(toggle => {
      if (touchSupported) {
        toggle.addEventListener('touchstart', skipClickDelay, false);
      }
      if (pointerSupported) {
        toggle.addEventListener('pointerdown', skipClickDelay, false);
      }
      toggle.addEventListener('click', switchAccordion, false);
    });

    return () => {
      accordionToggles.forEach(toggle => {
        if (touchSupported) {
          toggle.removeEventListener('touchstart', skipClickDelay, false);
        }
        if (pointerSupported) {
          toggle.removeEventListener('pointerdown', skipClickDelay, false);
        }
        toggle.removeEventListener('click', switchAccordion, false);
      });
    };
  }, [id]);

  return (
    <div className="container">
      <div className="accordion">
        <dl>
          <dt>
            <a href={`#${id}`} aria-expanded="false" aria-controls={id} className={`accordion-title accordionTitle js-accordionTrigger js-accordionTrigger-${id}`}>
             {title}
            </a>
          </dt>
          <dd className="accordion-content accordionItem is-collapsed" id={id} aria-hidden="true">
            <div className="containerContent">
            {content}
            </div>
          </dd>
          <dt>
          </dt>
        </dl>
      </div>
    </div>
  );
};

export default Accordeon;
