import React from 'react';


const Footer = () => {
  return (
    <>
      <div className='footer'>
        <p className='firs_line_footer'>
          Site web réalisé par Rony Lhomer - 2023
        </p>

      </div>
    
    
    
    
    </>
  );
};

export default Footer;
