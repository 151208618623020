import React, { useState, useEffect } from 'react';
import Paginated from './PaginationItems';
import ArticleBlog from './ArticleBlog';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import axios from 'axios';

const Blog = () => {
  const itemsPerPage = 3;
  const [articles, setArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true); 

  // Charger la page enregistrée
  useEffect(() => {
    const savedPage = localStorage.getItem('currentPage');
    if (savedPage) {
      setCurrentPage(Math.max(0, Number(savedPage))); 
    }
  }, []);

  // Sauvegarder la page actuelle lors du changement
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    localStorage.setItem('currentPage', newPage);
  };

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get('https://luckycheetah.fun/apibiographe/fetchnouvelles');
        const sortedArticles = response.data.documents.sort((a, b) => {
          const dateA = new Date(a.date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));
          const dateB = new Date(b.date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));
          return dateB - dateA;
        });
        setArticles(sortedArticles || []);
        setLoading(false);
      } catch (error) {
        console.error('Erreur lors de la récupération des articles:', error);
        setLoading(false);
      }
    };
    fetchArticles();
  }, []);

  const startIndex = currentPage * itemsPerPage;
  const currentItems = articles.slice(startIndex, startIndex + itemsPerPage);

  const itemCount = currentPage === 3 ? 2 : itemsPerPage; // Si c'est la 4ème page, afficher 2 squelettes

  return (
    <div className="sectionnouvelles">
      <div className='h-[70px]'></div>
      <h1 className='text-center py-5 font-gabriela text-2xl titreSectionN'>Mes nouvelles à découvrir</h1>
      <div className='flex justify-center align-items'>
        <Paginated 
          itemsPerPage={itemsPerPage} 
          currentPage={currentPage} 
          onPageChange={handlePageChange}>
          {loading ? (
            <LoadingSkeletons itemCount={itemCount} />
          ) : (
            <Items currentItems={currentItems} />
          )}
        </Paginated>
      </div>
    </div>
  );

  function LoadingSkeletons({ itemCount }) {
    return (
      <div className='flex flex-col justify-center md:flex-row sm:flex-wrap'>
        {Array.from({ length: itemCount }).map((_, index) => (
          <div key={index} className="flex align-items justify-center pb-5 mx-5">
            <div className='novelcard relative bg-green shadow-sm rounded-lg overflow-hidden w-[360px] sm:w-[420px] flex flex-col hover:bg-yellow-100'>
              <span className='px-5 py-2 text-white text-xs absolute left-0'>
                <Skeleton />
              </span>
              <Skeleton className="h-[270px] object-cover" />
              <div className="xav px-6 py-4 flex-1 bg-white contenuExtrait">
                <div className='inline-flex space-x-10'>
                  <Skeleton className='font-gabriela py-3 text-blue-900 text-xl' width={200} />
                  <Skeleton className='py-4 text-gray-500' width={130} height={44} />
                </div>
                <Skeleton className="text-gray-900 text-base h-[160px] text-justify" count={1} />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
  
  function Items({ currentItems }) {
    return (
      <div className='flex flex-col justify-center md:flex-row sm:flex-wrap'>
        {currentItems.map((article, index) => (
          <ArticleBlog 
            key={article._id} 
            index={article._id} 
            imagearticle={article.lienImage}
            titreArticle={article.titre} 
            extraitArticle={article.extrait}
            dateArticle={article.date} 
            lienDA={article.lienDA} 
          />
        ))}
      </div>
    );
  }
};

export default Blog;
