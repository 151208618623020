import React from 'react'
import presta1 from '../images/forfait111.webp'
import presta3 from '../images/forfait333.webp'
import presta2 from '../images/forfait222.webp'
import livres_prestations from '../images/prestations.gif'
import Lottie from 'lottie-react';
import animationData from '../../src/lottie_writter.json';
import {CgAsterisk} from 'react-icons/cg'
import BackToTop from './BackToTop'
const Prestations2 = () => {
  return (
    <>
    <div className='h-[100px]'></div>
    <h1 className='text-center text-3xl font-gabriela text-sky-900 title'>Mes prestations</h1>
    <div class="main">
        <style>
            {`
                *,
                *::before,
                *::after {
                box-sizing: border-box;
                }

                 @keyframes slideInFromLeft {
              from {
                transform: translateX(-100%);
                opacity: 0;
              }
              to {
                transform: translateX(0);
                opacity: 1;
              }
            }

            @keyframes slideInFromTop {
              from {
                transform: translateY(-100%);
                opacity: 0;
              }
              to {
                transform: translateY(0);
                opacity: 1;
              }
            }

            @keyframes slideInFromRight {
              from {
                transform: translateX(100%);
                opacity: 0;
              }
              to {
                transform: translateX(0);
                opacity: 1;
              }
            }
              @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }

            h1.title {
              opacity: 0;
              font-size:30px;
              animation: fadeIn 1s ease-out forwards;
              animation-delay: 1s; /* Délai pour attendre la fin des animations des cards */
            }

            .cards_item:nth-child(1) {
              animation: slideInFromLeft 1s ease-out forwards;
            }

            .cards_item:nth-child(2) {
              animation: slideInFromTop 1s ease-out forwards;
            }

            .cards_item:nth-child(3) {
              animation: slideInFromRight 1s ease-out forwards;
            }

                .main {
                max-width: 1500px;
                margin: 0 auto;
                }

                .cards {
                display: grid;
                grid-template-columns:repeat(auto-fit,minmax(300px,1fr));
                list-style: none;
                margin: 0;
                padding: 0;
                }

                .cards_item {
                display: flex;
                padding: 1rem;
                }

                .card_image {
                height: calc(13*1.2rem);
                padding: 1.2rem 1.2rem 0;
                position:relative;
                }
                .card_image:before,
                .card_image:after{
                content: "";
                position: absolute;
                width: 20px;
                left: 60%;
                top: 0;
                height: 45px;
                background: #e6e6e6b8;
                transform: rotate(45deg);
                }
                .card_image:after{
                transform: rotate(-45deg);
                top:auto;
                bottom:-22px;
                left:40%;
                }
                .card_image img {
                width:100%;
                height:100%;
                object-fit:cover;
                }

                .cards_item {
                filter:drop-shadow(0 0 5px rgba(0, 0, 0, 0.25));
                }


                .card {
                background-color: white;
                border-radius: 0.25rem;
                display: flex;
                flex-direction: column;
                overflow: hidden;
                padding-left: 30px;
                background: repeating-linear-gradient(#0000 0 calc(1.2rem - 1px),#66afe1 0 1.2rem) right bottom /100% 100%,linear-gradient(red 0 0) 30px 0/2px 100% #fff;
                background-repeat: no-repeat;
                line-height: 1.2rem;
                -webkit-mask:radial-gradient(circle .8rem at 2px 50%,#0000 98%,#000)0 0/100% 2.4rem;
                }

                .card_content {
                padding: 1.2rem;
                }

                h2.card_title,p {
                margin: 1.2rem 0;
                }
                h2.card_title {
                    font-size: 1.3em;
                }
                body {
                background: white;
                }

                html {

                }
                `}
            </style>


  <ul class="cards">
    <li class="cards_item">
      <div class="card">
        <div style={{ backgroundImage: `url(${presta1})` }} className="card_image bg-cover"></div>
        <div class="card_content text-indigo-900">
          <h2 class="card_title text-center">❝Histoire brève❞ &#x2022; 200€/séance<CgAsterisk className='inline text-red-600 text-2xl'/></h2>
         
          <div class="card_text text-center">
            <p className='text-justify'>Vous voulez partager un moment fort de votre vie avec vos proches mais n'avez pas le temps de rédiger vous-même un texte ? Mon forfait "Histoire brève" est ce qu'il vous faut ! Je vous accompagne pour transformer votre histoire en un récit émouvant et facile à lire.</p>
            <p><span className='px-[21px] py-1 text-black font-bold rounded-full'>Estimation donnée à titre indicatif: entre 1 et 3 séances</span></p>
          </div>
        </div>
      </div>
    </li>

    <li class="cards_item">
      <div class="card">
        <div style={{ backgroundImage: `url(${presta2})` }} class="card_image bg-cover"></div>
        <div class="card_content text-cyan-900">
          <h2 class="card_title text-center">❝Tranche de vie❞ &#x2022; 200€/séance<CgAsterisk className='inline text-red-600 text-2xl'/></h2>
          <div class="card_text text-center">
            <p className='text-justify'>Vous avez envie de transmettre votre histoire à vos enfants ou petits-enfants mais vous ne savez pas par où commencer ? Mon forfait "Tranche de vie" est là pour vous guider dans cette aventure ! Nous échangeons ensemble pour trouver les moments les plus importants de votre vie à partager et je vous aide à les retranscrire de manière captivante.</p>
            <p><span className='px-[21px] py-1 text-black font-bold rounded-full'>Estimation donnée à titre indicatif: entre 3 et 5 séances</span></p>
          </div>
        </div>
      </div>
    </li>
    <li class="cards_item">
      <div class="card" >
        <div class="card_image bg-cover" style={{ backgroundImage: `url(${presta3})` }}></div>
        <div class="card_content">
          <h2 class="card_title text-center text-yellow-900">❝Biographie complète❞ &#x2022; 200€/séance<CgAsterisk className='inline text-red-600 text-2xl'/></h2>
          <div class="card_text text-center">
            
            <p className='text-yellow-800 text-justify'>Vous voulez laisser un héritage durable à vos descendants ? Mon forfait "Biographie complète" est la solution idéale ! Nous travaillons ensemble pour écrire l'histoire de votre vie, de votre enfance à votre vie professionnelle en passant par tous les moments marquants de votre existence. Le résultat final sera un livre qui vous ressemble, qui vous rend hommage et que votre famille pourra chérir pour les générations à venir.</p>
            <p><span className='px-[21px] py-1 text-black font-bold rounded-full'>Estimation donnée à titre indicatif: 6 séances et plus</span></p>
        
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>
  <div className='font-gabriela text-center text-xl pt-5'>
  <CgAsterisk className='inline text-red-600 text-2xl'/>200 Euros pour <span className='underline'>8 heures de travail </span>(1 heure d'entretien puis 7h de retranscription et rédaction, seul à mon bureau)
  </div>

<section className='p-2 m-3 sm:p-5 sm:m-10'>
  <hr></hr>

  <div>
    <h1 className='text-center text-3xl font-gabriela text-sky-900 pb-5 pt-5'>Votre livre sur-mesure</h1>
    <div className='lottieContainer'>
      <Lottie animationData={animationData} loop={true} className='lottieFile' />
    </div>
  </div>

  <div className='text-justify text-lg font-gabriela'>
    <h2 className='font-gabriela text-2xl text-sky-900'>Tarifs d'une biographie</h2>
    <p>
      Parce que chacun est unique, pas question d’un paiement au forfait ! Sauf si vous préférez tout régler d’un coup pour ne plus y penser, ou parce qu’il s’agit d’un cadeau des petits-enfants au grand-père, par exemple. Dans ce cas, je vous établirai un devis forfaitaire après que vous m’avez bien présenté votre projet. 

      <br/>Sinon, vous réglez le travail à l’heure ou plus exactement à la séance de travail, c’est-à-dire une heure d’entretien + le temps d’écriture, qui varie selon la rapidité de votre débit et la manière plus ou moins précise et chronologique dont vous restituez vos souvenirs. 
      <br/>Pour une biographie familiale, chaque heure est facturée 25€ ( à titre d'indication, le tarif appliqué par le réseau d’écrivains biographes NPI, est de 70 euros) : temps d’entretien, d’écriture, de correction et modification, de déplacement, de lecture d’un document existant, etc. 

      <br/>A vous de choisir la formule qui vous convient : payer à chaque séance pour échelonner vos paiements, ou choisir un forfait de plusieurs séances pour éviter d’avoir à sortir votre porte-monnaie à chaque rencontre. 
    </p>
  </div>

  <div className='text-justify text-lg font-gabriela'>
      <h2 className='font-gabriela text-2xl text-sky-900'>Réglement</h2>
      <p>
        Le règlement se fait, soit sous forme de forfait de plusieurs heures, soit à la fin de chaque séance.<br/>
        <span className='underline font-bold text-sky-900'>Cela vous permet d’arrêter à tout moment si vous le décidez</span>. Cela reste votre droit absolu, quelle qu’en soit la raison.
        <br></br>A noter que, pour m’assurer de votre engagement personnel et éviter toute déconvenue et perte de temps vis-à-vis de mes autres clients, je sollicite le paiement d’une séance en avance, à régler lors de notre première séance de travail (c’est-à-dire à notre deuxième rencontre, la première étant informelle)
        <br></br>Si vous respectez cet engagement et que nous menons à terme notre collaboration, votre dernière séance sera gratuite. En revanche, si vous désirez mettre un terme à notre travail, l’avance ne vous sera pas restituée, à titre de dédommagement.
        Toute séance annulée moins de 48h avant le rendez-vous est due.
      </p>
  </div>


  <div className='text-justify text-lg font-gabriela'>
      <h2 className='font-gabriela text-2xl text-sky-900'>Budget moyen d'un livre</h2>
    <p>
        Ces 7 heures incluent le travail de rédaction mais aussi celui de <span className='text-sky-900 font-bold'>« sublimation » </span>: je ne me contente pas de retranscrire vos propos, je mets aussi en valeur votre récit dans un numéro d’équilibriste consistant à faire entendre votre voix tout en soignant le style d’écriture. 
        <br></br>Il y a aussi tout le travail de <span className='text-sky-900 font-bold'>structuration</span> : je mets en forme, j’agence les différents éléments en jouant parfois avec la temporalité et en ordonnant les passages pour que la lecture soit la plus agréable, fluide et captivante possible. 
        <br></br>Tous les ouvrages biographiques n’ont pas vocation à être publiés ; pour autant, ils ont pour moi tous vocation à être lus et je les rédige toujours avec l’intention que mes mots soient entraînants, touchants et intéressants pour celui qui va les lire. 
        <br></br>Enfin, je m’adonne au travail d’<span className='text-sky-900 font-bold'>illustration</span>: j’intègre les photos, lettres, éléments que vous m’avez partagés pour illustrer votre récit. J’intègre parfois aussi des encadrés historiques quand la petite histoire s’inscrit dans la grande Histoire. 
        <br></br>Enfin, sont inclus dans les 7h « en coulisses » le <span className='text-sky-900 font-bold'>travail de correction</span> indissociable de tout projet de co-écriture. 
        <br></br>Ainsi, <span className='underline font-bold text-sky-900'>après chaque séance, je vous envoie mes écrits </span>et prends soigneusement en compte vos retours. J’étoffe, j’allège, je saupoudre, je pimente, j’adoucis, selon vos goûts et vos envies. 
        <br></br>Mon objectif est que vous vous sentiez en parfaite adéquation avec la version finale, que vous puissiez dire : « c’est exactement ce que je voulais dire et comme ça que je voulais le dire ».  
    </p>

    <p>
      Pour un roman, le temps d’écriture est plus long ; il faut compter 9h de travail d’écriture pour chaque entretien.
      Chaque séance d’entretien d’une heure représente donc 200€.
      À noter qu’une biographie complète revient en moyenne à un coût total de 4300 à 6400€ (hors frais d’édition spécifiés ci-dessous). 
    </p>

    <p>
      C’est vous qui déterminez si vous souhaitez une séance par semaine, par quinzaine, par mois… grâce à quoi vous échelonnez le paiement du travail sur des mois, voire plusieurs années. 
      <br></br>Ceci étant dit, <span className='underline font-bold text-sky-900'>je préconise une séance hebdomadaire d’une heure pour une avancée régulière et fluide du projet.</span>
      <br></br>Comptez en plus le coût d’impression de votre livre. A titre indicatif, un livre de 220 pages (avec 10 pages couleur) vous coûtera approximativement 220€ pour 10 exemplaires (en comptant l’épreuve). Les tarifs sont dégressifs en fonction de la quantité d’exemplaires commandée.
    </p>

  </div>
  <BackToTop/>

</section>



</>
  )
}

export default Prestations2