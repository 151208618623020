import React from 'react'
import Menu4 from './Menu4'

const Menu4FinalWrap = () => {
  return (
    <>
    
    <Menu4/>

    
    </>
  )
}

export default Menu4FinalWrap