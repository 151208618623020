import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import BackToTop from './BackToTop';
import axios from 'axios';
import { FaArrowCircleLeft } from 'react-icons/fa';

const AffichageNouvelle = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/blog`);
  };

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await axios.get(`https://luckycheetah.fun/apibiographe/nouvelle/${id}`);
        if (response.data && response.data.document) {
          setArticle(response.data.document);
        } else {
          navigate('/404'); // Redirige vers la page 404 si l'article n'est pas trouvé
        }
      } catch (error) {
        console.error('Erreur lors de la récupération de l\'article:', error);
        navigate('/404'); // Redirige vers la page 404 en cas d'erreur
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [id, navigate]);

  if (loading) {
    return <div>Chargement en cours...</div>; // Afficher un message de chargement pendant le chargement de l'article
  }

  return (
    <div className="affichageNouvelle">
      <div className='h-[100px]'></div>
      <div className=''>
        {/* Affichage des données de l'article */}
        <h1 className='text-center font-gabriela py-3 text-blue-900 text-3xl'>{article.titre}</h1>

        <div className='boutonRetour' onClick={handleClick}>
          <span className='iconFA'>
            <FaArrowCircleLeft /> 
          </span>
          Retour
        </div>

        <div className='pb-8'>
          <p className='font-playfair mx-auto px-5 text-justify max-w-[90%]' dangerouslySetInnerHTML={{ __html: article.texte }}></p>
        </div>
        <div className='pb-8 text-center'>
          Patrick Collet
        </div>
      </div>
      <BackToTop />
    </div>
  );
};

export default AffichageNouvelle;
