import React from 'react'
import errr from '../images/404.webp'
import Footer from './Footer'
const Erreur = () => {
  return (
    <div className='wrapper'>
      <div className='h-[100px]'></div>

      <div className='h-[80vh]'>

      <h1 className='text-center py-5 text-red-800 text-xl'>
        Page introuvable
      </h1>
      <div className='flex justify-center items-center pb-5'>
                        <img src={errr} alt="ojoj" className='w-[380px] h-[380px]
                        md:w-[380px] md:h-[380px]
                        flex justify-center rounded-full object-cover'/>
      </div>

      </div>
    </div>
  )
}

export default Erreur