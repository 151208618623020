
import React from 'react';
import Accordeon from './Accordeon';


const AccordeonComponent2 = () => {
  const questionReponse = [
    { k: '1', q: "Où nous rencontrons-nous ?", c: `Nous pouvons nous rencontrer chez vous, lieu idéal pour que vous vous sentiez complètement à l’aise, dans un espace familier propice à faire remonter vos souvenirs. Je vis au cœur du Vieux Nice mais je me déplace dans les Alpes-Maritimes et à Monaco. Il faudra juste dans ce cas prendre en compte le temps et mes frais de déplacement, à savoir le tarif des transports en commun + le temps de déplacement (60€/heure).` },
    { k: '2', q: "Comment se passe le premier échange ?", c: `Le premier échange se fait par téléphone. Vous me racontez votre projet, nous faisons connaissance, je réponds à toutes vos questions. Nous voyons si le courant passe bien entre nous, si vos attentes et ma manière de travailler peuvent se rencontrer. Pour vous comme pour moi, un bon feeling est essentiel pour collaborer sur une telle aventure d’écriture. Il est important que vous vous sentiez en confiance et à l’aise avec moi et réciproquement, nous allons passer du temps ensemble et devons former une bonne équipe ! Si nous sentons que nous sommes sur la même longueur d’ondes et que vous décidez de vous lancer dans cette belle aventure, nous établissons un planning d’entretiens en fonction de vos disponibilités et de la date à laquelle vous voulez avoir le livre entre vos mains. Je vous inviterai à m’amener, dès la première séance, des photos pour vous accompagner dans votre récit et stimuler votre mémoire. Elles pourront si vous le souhaitez être intégrées à votre livre.` },
    { k: '3', q: "Comment se déroule une séance ?", c: `Le processus de création d'une biographie implique généralement une série d'entretiens entre le biographe et le client. Pendant ces entretiens, le biographe recueille des informations sur la vie du client, y compris ses expériences, ses relations et ses réalisations, et pose des questions pour clarifier et approfondir les détails. Nous nous voyons en tête à tête, vous me racontez votre histoire, si possible chronologiquement, je prends des notes et vous enregistre avec mon dictaphone, pour éviter de vous interrompre, et pour bien garder à l’oreille votre manière de vous exprimer, l’idée étant de rédiger un livre à votre image. Au cours des séances, il se peut que je vous guide ponctuellement dans votre récit. Certaines personnes ne savent pas par où commencer, mes questions visent à les aider et orienter ; d’autres sont au contraire emportés par le flot des souvenirs, mes questions visent à en tirer « la substantifique moelle »…` },
    { k: '4', q: "Combien de temps dure une séance ?", c: "Une séance dure 1H. Toutefois la durée des entretiens peut varier sans dépasser 1H30 et sans supplément de tarif." },
    { k: '6', q: "Est-il possible de faire la séance en visio ?", c: "Oui, les entretiens peuvent se faire en visioconférence via des logiciels tels que Skype, Zoom ou Discord. Ces logiciels sont gratuits." },
    { k: '7', q: "Combien de séances au minimum ?", c: "Le nombre de séances nécessaires pour créer une biographie varie en fonction de la complexité de l'histoire du client. Dans la plupart des cas, plusieurs entretiens seront nécessaires pour recueillir toutes les informations nécessaires pour créer une biographie complète et précise." },
    { k: '8', q: "Combien de temps est conservé un enregistrement vocal ?", c: "Les enregistrements vocaux sont conservés pour aider le biographe à transcrire les informations recueillies pendant les entretiens. La durée de conservation des enregistrements vocaux peut varier en fonction des préférences du biographe et du client. Dans la plupart des cas, une fois que le travail est terminé, les enregistrements vocaux sont supprimés pour protéger la confidentialité du client." },
    { k: '9', q: "Comment se passe l'écriture du livre ?", c: `Après chaque séance, je retranscris vos paroles en enrichissant le vocabulaire et les verbes, en supprimant les répétitions, en gommant les maladresses de langage, tout en veillant à préserver votre « petite musique » bien à vous, vos expressions, vos intentions, vos émotions. A part si vous me demandez explicitement de romancer votre livre, je tâche d’être le plus fidèle possible à votre ton. En aucun cas je ne vous impose mon style ; je suis là pour faire valoir le vôtre, l’idée étant que l’on dise en lisant votre livre : «je te reconnais bien là ! ». Je fais en sorte que votre livre vous ressemble, vous reflète, vous incarne. Je n’écris évidemment que ce que vous m’autorisez à écrire, hormis tous propos diffamatoires répressibles par la loi autant pour vous que pour moi. Je ne suis pas documentaliste, je ne fais pas de recherches et vous laisse le soin de vérifier vos informations. J’écris entre chaque séance et vous soumets mes écrits au fur et à mesure de sorte que vous puissiez les modifier à votre guise. Vous aurez ainsi le plaisir de voir votre livre prendre corps pas à pas sous vos yeux…` }
  ];

  return (
    <div className='py-3'>
      <h2 className='text-3xl text-indigo-800 font-bold text-center font-gabriela'>Foire aux questions</h2>
      <div className='py-3'>
        {questionReponse.map(item => (
          <Accordeon key={item.k} title={item.q} content={item.c} id={`accordion${item.k}`} />
        ))}
      </div>
    </div>
  );
};

export default AccordeonComponent2;
