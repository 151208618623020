import React from 'react';
import { useNavigate } from 'react-router-dom';

const ArticleBlog = ({ index, imagearticle, lienDA, titreArticle, extraitArticle, dateArticle }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/nouvelle/${index}`);
  };

  return (
    <div className='flex align-items justify-center pb-5 mx-5'> 
      <a key={index} index={index} onClick={handleClick} className='no-underline transition duration-500 transform'>
        <div className='novelcard relative bg-green shadow-sm rounded-lg overflow-hidden w-[360px] sm:w-[420px] flex flex-col hover:bg-yellow-100'>
          <span className='px-5 py-2 text-white text-xs absolute left-0' dangerouslySetInnerHTML={{ __html: lienDA }}></span>
          <img className="h-[270px] object-cover " src={imagearticle} alt="Article"></img>
          <div className="xav px-6 py-4 flex-1 bg-white contenuExtrait">
            <div className='inline-flex space-x-10'>
              <h2 className='font-gabriela py-3 text-blue-900 text-xl'>{titreArticle}</h2>
              <p className='py-4 text-gray-500'>{dateArticle}</p>
            </div>
            <p className="text-gray-900 text-base h-[160px] text-justify">{extraitArticle}</p>
          </div>
        </div>
      </a>
    </div>
  );
};

export default ArticleBlog;
