import React from 'react';
import { useNavigate } from 'react-router-dom';
import photoAuteur from '../images/auteur3.webp';
import DemarcheMethode from './DemarcheMethode';
import SimpleMap from './SimpleMap';
import {GiRotaryPhone} from 'react-icons/gi';
import nuage from '../images/nuages3.webp';
import BootstrapSlyde from './SliderComponent';


const PresentationAuteur = () => {
    const navigate = useNavigate();

    function handleClick() {
        navigate('/prestations');
    }
    function handleClickBlog() {
        navigate('/blog');
    }
    return(
        <div>
            <div className='h-[70px]'></div>
            <div>
            <BootstrapSlyde/>
            </div>


            <div style={{ backgroundImage: `url(${nuage})` }} className='bg-cover py-5'>
                
                <div className='py-5' >
                    <h1 className='text-center font-gabriela text-3xl text-white font-bold py-3'>
                        Votre biographe Niçois <br/>
                        <GiRotaryPhone className='mx-auto inline text-4xl'></GiRotaryPhone><span className=''> 07.58.49.09.60</span></h1>
                </div>

                <testAccess/>

                <div className='flex flex-col justify-center
                xl:flex-row
                '>
                    
                    <div className='flex justify-center items-center pb-10 sm:pb-10'>
                        <img src={photoAuteur} alt="ojoj" className='w-[290px] h-[290px]
                        md:w-[400px] md:h-[400px]
                        flex justify-center rounded-full object-cover shadow-2xl'/>
                    </div>


                    <div className='flex items-center px-4 text-justify text-lg font-cormorantgaramond text-zinc-700
                    xl:w-1/2'>

                        <section className='flex flex-col px-5'>
                            <div className='font-playfair'>
                                <p className='text-zinc-800'>
                                    <span className=''>Qui suis-je ? </span>
                                    Pour être sincère, je n’aime pas trop parler de moi. Je préfère écouter, observer, découvrir autrui et ses émotions …
                                    Mais alors comment vous forger un avis ?
                                    C’est important car vous allez peut-être vous confier à moi.Donc je me lance.
                                </p><br></br>

                                <p>
                                    J’ai 47 ans. J’ai grandi et étudié le droit à Nantes. Pour financer mes études je travaillais comme 
                                    agent de sécurité. J’ai été serveur à Londres, chauffeur de maître, sous les drapeaux à l’école du Génie 
                                    où j’ai fait un peloton d’élève gradé, syndic de copropriété à Paris, juriste de banque, agent d’assurance au GAN, 
                                    Conseiller expert en assurance-vie à AG2R la Mondiale, castreur de maïs, vendeur d’extincteurs, tailleur et ramasseur 
                                    d’olives dans l’arrière pays niçois. J’ai fait deux ans de théâtre classique pendant mes études et j’ai tenu des premiers 
                                    rôles tel Cinna de Corneille et Hippolyte d’Euripide.
                                </p><br></br>

                                <p className='text-zinc-800'>

                                    En 2008 j’ai quitté Paris et la vie de bureau pour Nice. J’ai voulu donner un peu de moi aux pauvres et ce sont les pauvres qui m’ont enrichi. Je suis devenu volontaire bénévole en banlieue dans un quartier « chaud » de l’est de Nice. J’étais nourri et logé dans la paroisse catholique de l’Ariane et je participais à la confection de 300 repas par jour que nous servions aux SDF, le soir dans les rues du centre ville. Puis le curé de la paroisse m’a demandé de créer et de gérer une épicerie sociale pour 600 familles.
                                    Aujourd’hui je suis animateur collecteur bénévole au Secours Populaire à Nice. 

                                </p><br></br>
                                
                                <p>

                                    J’écris des nouvelles et des poèmes que vous pouvez <span className='font-bold text-blue-900 underline'><a href='#' onClick={handleClickBlog} >lire sur mon blog</a></span> et passer par toutes les émotions du rire aux larmes.
                                    Je suis membre du club de voile de Nice.J’ai couru trois marathons.

                                </p><br></br>

                                <p>
                                    Un tel CV peut donner des frissons non conventionnels.
                                    Je ne regrette pas plus mon parcours que je ne veux l’oublier. J’ai été chanceux.
                                </p><br></br>

                                <p className='text-zinc-800'>
                                    Pourquoi alors devenir Ecrivain Biographe ? Car écrire sur les autres est une grande partie de ce qui me constitue.
                                    Je pense que l’écoute de vos mots et de vos émotions imprime la base de ce métier que j’aime et je veux jeter l’encre sur le papier en relatant le plus précisément possible ce que vous avez à me dire, afin que jamais vos plus beaux souvenirs ne disparaissent dans l’écume des vagues et des jours.
                                    J’arrête ici avec cette phrase de Blaise Pascal : « Si vous voulez que l’on dise du bien de vous, n’en dites jamais. »
                                </p>
                            </div>
                                               

                            <div className='flex justify-center py-10'>

        <button class="button-77" role="button" onClick={handleClick}>voir les prestations</button>
                            
                            </div>

                    </section>

                </div>
                </div>

            </div>
            <DemarcheMethode/>
           
           <div>
                <section className='flex-row justify-center bg-blue-300'>
                    <h2 className='flex justify-center text-3xl text-indigo-800 font-bold text-center font-gabriela p-3'>Rencontrons nous !</h2>
                    <div className='text-center font-gabriela text-xl'>19, rue Neuve</div>
                    <div className='text-center font-gabriela text-xl'>06300 Nice</div>
                    <div className='flex justify-center pt-5'>
                        <SimpleMap/>

                    </div>
                </section>

            </div>

        </div>

    )
}


export default PresentationAuteur